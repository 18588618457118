
html,
body {
  padding: 0px;
  margin: 0px;
}

#root {
  height: 100% !important;
  height: calc(var(--vh, 1vh) * 0.01) !important;
}


.btnLang{position: absolute;top: 0%;right: 0;z-index: 999999;background-color: rgba(255,255,255,0.75);
          width: 75px;height: 44px;-webkit-border-bottom-left-radius: 5px;-moz-border-radius-bottomleft: 5px;border-bottom-left-radius: 5px;}

.btnLang a{color: #323232;cursor: pointer;font-weight: 600;width: 100%;height: 100%;display: flex;
          justify-content: center;align-items: center;}

.square.x-axis {
  animation: x-axis-animation 1s ease-in-out 0s;
}
@keyframes x-axis-animation {
  from {transform: rotateX(0deg)}
  to {transform: rotateX(-180deg)}
}       


.ui.card>.image, .ui.cards>.card>.image{background-color:transparent!important;}

.logoInicio{width:250px!important;}
.ui.grid>.row{justify-content: center!important;}

/*INICIO PAGE*/
.ui.menu.three.item .item div{display:flex;align-items: center;}
.btn-inicio{display: flex;align-items: center;color: #323232;float: right;position: relative;padding: 0.92857143em 1.14285714em;font-weight: bolder;}
.btn-inicio:hover{text-decoration: none;color:#323232;background: rgba(0,0,0,.03);}
.inicio{background-color:#f9f9f9!important;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(../img/motel-los-pinos-banner.jpg)!important;
  background-position: 50% 0%!important;background-size: cover!important;
}
.inicio .ui.grid>.ui.grid:last-child{display:flex;align-items: center;}
.inicio .ui.item.menu, .inicio .ui.item.menu .item{
  display:block;
  background:transparent;
  border:none;
}
.inicio .ui.menu.one.item .item{
  width:100%;
  margin: 35px 0px;
  background-color: transparent;
  border:1px solid #f9f9f9;
  border-radius: 5px;
  font-weight: 600;
  color:#f9f9f9;
  font-size: 16px;
  padding:10px;
  transition:0.25s;
}
.inicio .divbtn a{
  width:100%;
  margin: 20px 0px!important;
  border:1px solid #f9f9f9;
  background-color: transparent;
  margin:0;
  padding:10px;
  display:block;
  color:#f9f9f9;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  transition:0.25s;
}
.inicio .ui.menu.one.item .item:hover, .inicio .divbtn a:hover{background-color:#f9f9f9;color:#323232;}
.inicio i.icon, .inicio i.icons{margin:0 0 5px 0px!important;text-shadow: none!important;width: inherit;}


.inicio-background h1{color:#f9f9f9;text-align: center;font-size:50px;
  background-color:#010101;width: 75%;font-size:30px;display:flex;
  justify-content: center;margin-right: 5%;margin-left: auto;}
.inicio-background h2{background-color:#f9f9f9;color:#323232;
  width: 75%;font-size:30px;display:flex;justify-content: center;    
  margin-right: auto;margin-left: 5%;transform: rotate(5deg);}
.inicio-background h2::after{font-size:20px;content: 'EXTENSIÓN';position: absolute;left:0;bottom:-40px;}

.inicio-background .extensao{width:75px;}
.inicio-background .los_pinos{width:200px;margin-bottom:60px;}

.avaliar-menu{
  padding: 0.92857143em 1.14285714em;
  border-bottom:1px rgba(0,0,0,0.09) solid;
}
.avaliar-menu a{display:block;text-align: center;text-decoration: none;color:#323232;}
.avaliar-menu i{width: inherit;display: block;}
/*INICIO PAGE*/


.all {
  display: flex;
  flex-direction: column;
  height: 100vw;
}
.allCentro {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

#main {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

#mainCentro {
  flex: 1;
  overflow: auto;
  padding: 0px;
  margin: 0px;

}

.padding-0 {
  padding: 0px !important;
}

.margin-0 {
  margin: 0px !important;
}

.p-relative {
  position: relative !important;
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.h-100 {
  height: 100% !important;
  max-height: 100% !important;
}
.minh-100 {
  min-height: 100% !important;
}
.h-30 {
  height: 30% !important;
}
.h-70 {
  height: 70% !important;
}
.h-20 {
  height: 20% !important;
}
.h-80 {
  height: 80% !important;
}
.h-50 {
  height: 40% !important;
  width: 100% !important;
}
.onBottom {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.padding-20 {
  padding: 20px !important;
}

.margin-20 {
  margin: 20px !important;
}

.v-center {
  position: relative;
}

.v-center-in {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.texto-centro {
  text-align: center !important;
}

.text-color-white {
  color: #cacbcd !important;
}

.text-color-branco {
  color: #ffffff !important;
  font-size:12px;
}

.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}

.border-0 {
  border: unset !important;
}

.banner {
  padding-left: 5px !important;
  padding-right: 5px !important;

  height: 30vh !important;
  max-height: 230px !important;
  min-height: 180px !important;
  width: 100%;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.banner-inside-category {
  height: 20vh !important;
  max-height: 120px !important;
  min-height: 90px !important;
  width: 100%;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-label-position {
  position: absolute;
  font-size: 30px !important;
  text-shadow: 2px 2px #403d39;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%;
}

.inicio-background {
  min-height: 100% !important;
  position: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0cd01c !important;
}

@media screen and (max-width: 767px) {
  .ui.container {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

.mainP1 {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.mainCentroP1 {
  flex: 1;
  overflow: auto;
  padding: 0px;
  margin: 0px;
}

.grid-produto-title {
  line-height: 1.3em !important;
}
.grid-produto-preco {
  margin-top: 5px !important;
  line-height: 1.2em !important;
}

.margin-top10 {
  margin-top: 5px !important;
}

.margin-top0 {
  margin-top: 0px !important;
}

.text-align-center {
  text-align: center;
}

.icon-tabaco {
  background: url("./../img/cigarrete.png") no-repeat fixed right;
  /* height: 28px !important;
  width: 28px !important; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

/* cores */
.bg-dark {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bg-orange {
  background-color: #403d39 !important;
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

/* cores */

/*ALTERACOES SEMANTIC*/
input,
.ui.input,
.ui.grid,
.ui.menu,
.ui.segment,
.ui.button,
.ui.modal,
.ui.card,
.ui.cards > .card {
  border-radius: unset !important;
  box-shadow: unset !important;
}
.ui.grid{margin: 0!important;}

i.icons .top.right.corner.icon {
  top: -5px !important;
  right: -5px !important;
}

.ui.segment {
  border: 0px !important;
}
.ui.segment:last-child{margin-top:0px;}

.ui.button {
  height: 50px !important;
}

.ui.list {
  font-size: 1.1em;
}

.ui.item {
  font-size: 1.1em;
}

.ui.card {
  margin: 0px !important;
}

.ui.menu .item:before {
  width: unset !important;
}

.ui.items > .item > .content > .header:not(.ui) {
  font-size: 1.4em;
}

.ui.card {
  margin: 0 auto !important;
}

.ui.card > .image {
  height: 120px !important;
  width: auto !important;
}

body ::-webkit-scrollbar {
  width: 4px !important;
}

/*ALTERACOES SEMANTIC*/
.column-card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
